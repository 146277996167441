import request from '@/utils/request'
import ENDPOINT from '@/config/endpoint'

export function getOrganizationActions(params) {
  return request({
    url: ENDPOINT.ORGANIZATION_ACTIONS,
    method: 'get',
    params
  })
}

export function createOrganizationAction(data) {
  return request({
    url: ENDPOINT.ORGANIZATION_ACTIONS,
    method: 'post',
    data
  })
}

export function deleteOrganizationAction(id) {
  return request({
    url: ENDPOINT.ORGANIZATION_ACTIONS,
    method: 'delete',
    params: {
      id: id
    }
  })
}

export function updateOrganizationAction(data) {
  return request({
    url: ENDPOINT.ORGANIZATION_ACTIONS,
    method: 'patch',
    data
  })
}

export function createOrganizationActionAssign(data) {
  return request({
    url: ENDPOINT.ORGANIZATION_ACTION_ASSIGNEES,
    method: 'post',
    data
  })
}
