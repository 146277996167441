<template>
  <div class="org-users shared-index">
    <h3 class="m-0">Phân quyền tài khoản</h3>
    <el-row class="table-title" type="flex" align="center" justify="space-between">
      <div class="table-title__left">
        <el-tabs value="orgUsers" type="card">
          <el-tab-pane name="orgUsers">
            <div slot="label">
              Danh sách tài khoản
              <span>({{ total }})</span>
            </div>
          </el-tab-pane>
        </el-tabs>
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          size="small"
          @click="visible = true"
        >Thêm tài khoản</el-button>
      </div>
      <div class="table-title__right">
        <el-input
          v-model="searchText"
          suffix-icon="el-icon-search"
          placeholder="Tìm kiếm theo email tài khoản"
          size="small"
          @input="handleSearch"
        ></el-input>
      </div>
    </el-row>
    <div v-loading="callingAPI" class="table">
      <list-users
        :data="listOrgUsers"
        :owner="orgOwner"
        :loading="loading"
        @delete="handleDeleteUser"
      ></list-users>
    </div>
    <el-row type="flex" align="middle" justify="end" class="pagination">
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 20, 30]"
        :page-size.sync="limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleChangeSize"
        @current-change="handleChangePage"
      ></el-pagination>
    </el-row>
    <add-users-popup
      v-if="visible"
      :visible="visible"
      :organization="organization"
      :owner="orgOwner"
      @reload="handleReload"
      @close="visible = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { removeMember } from '@/services/organizations'
import { getDataJs, orgContents } from '@/utils/filters'
import ListUsers from './users/List'
import AddUsersPopup from './popups/AddUsers'

export default {
  name: 'OrgUsers',
  components: {
    ListUsers,
    AddUsersPopup
  },
  props: {
    organization: Object
  },
  data() {
    return {
      loading: false,
      visible: false,
      callingAPI: false,
      searchText: '',
      limit: 10,
      page: 1
    }
  },
  computed: {
    ...mapGetters(['language']),
    orgOwner() {
      return this.organization.members.find((m) => m.role === 'OWNER')
    },
    allOrgUsers() {
      return orgContents('users', this.organization).filter((u) => u.staff_type === 'moderator')
    },
    listOrgUsers() {
      const data = getDataJs(this.allOrgUsers, this.page, this.limit, this.searchText, 'email')
      return data.data.map((d) => {
        const member = this.organization.members.find((m) => m.user_id === d.id)
        return { ...d, ...member }
      })
    },
    total() {
      const data = getDataJs(this.allOrgUsers, this.page, this.limit, this.searchText, 'email')
      return data.total
    }
  },

  methods: {
    handleChangePage(page) {
      this.page = page
    },

    handleChangeSize(size) {
      this.limit = size
      this.page = 1
    },

    handleSearch(text) {
      this.searchText = text
      this.page = 1
    },

    async handleReload() {
      this.loading = true
      await this.$emit('reload')
      this.loading = false
    },

    handleDeleteUser(user) {
      this.$confirm('Bạn có chắc chắn muốn tài khoản khỏi tổ chức này không?', 'Cảnh báo', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Hủy',
        type: 'warning'
      }).then(() => {
        this.loading = true
        removeMember({
          organization_id: this.organization.id,
          assigner_id: this.orgOwner.user_id,
          assignee_id: user.user_id
        }).then(async () => {
          this.$notify.success({
            title: 'Thành công',
            message: 'Xóa tài khoản khỏi tổ chức thành công'
          })
          await this.$emit('reload')
          if (this.listOrgUsers.length === 1 && this.page > 1) {
            this.page = this.page - 1
          }
          this.loading = false
        }).catch(() => {
          this.$notify.error({
            title: 'Thất bại',
            message: 'Không thể xóa tài khoản khỏi tổ chức'
          })
          this.loading = false
        })
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
